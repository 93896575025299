import { InputAdornment, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { roleOptions } from '../shared/profile.service';

const Role = ({ control, verified }) => {
  return (
    <>
      <div className="form-row flex-container">
        <div className="field margin-top--md margin-bottom--xs">
          <Controller
            name="role"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!error}
                required={verified}>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300
                      }
                    }
                  }}
                  labelId="role-label"
                  value={value}
                  onChange={onChange}
                  label="Role"
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonOutlineOutlinedIcon color="primary" />
                    </InputAdornment>
                  }>
                  {roleOptions.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
                {error && <span style={{ color: 'red' }}>{error.message}</span>}
              </FormControl>
            )}
            rules={verified ? { required: 'Please fill this field' } : {}}
          />
        </div>
      </div>
      <style jsx>{`
        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Role;
