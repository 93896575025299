import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore';
import { database } from '../../../helper/firebase.helper';

export const getDomainFromDb = async (orgId) => {
  const domainDocRef = doc(database, `organisations`, orgId);
  const domainDocSnap = await getDoc(domainDocRef);
  return {
    siteDomain: domainDocSnap.get('siteDomain')
  };
};

export const checkForDomain = async (domain) => {
  try {
    const q = query(
      collection(database, 'organisations'),
      where('siteDomain', '==', domain),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  } catch (error) {
    console.error('Error checking for domain: ', error.message);
    throw error;
  }
};

export const storeDomain = async (orgId, domainData) => {
  console.log(orgId);
  const domainDocRef = doc(database, `organisations`, orgId);

  return await setDoc(domainDocRef, domainData, { merge: true });
};
