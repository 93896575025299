import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, FormControl, IconButton, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { timeSlotOption, timeSlotOption1 } from './availability.service';
import toast from 'react-hot-toast';

const DayItem = ({ id, day, isActive, periods, setLocations, setAvailabilityChanged }) => {
  const getDayName = (day) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[day];
  };
  const dayName = getDayName(day);

  const handleDayChange = (id, day) => {
    setLocations((prev) =>
      prev.map((availabilityItem) => {
        if (availabilityItem.id === id) {
          const updatedDayAndTime = availabilityItem.availability.map((item) => {
            if (item.day === day) {
              return { ...item, isActive: !item.isActive };
            }
            return item;
          });
          setAvailabilityChanged(true);
          return { ...availabilityItem, availability: updatedDayAndTime };
        }

        return availabilityItem;
      })
    );
  };

  const addTimePeriod = (id, day) => {
    setLocations((prev) =>
      prev.map((locationItem) => {
        if (locationItem.id === id) {
          return {
            ...locationItem,
            availability: locationItem.availability.map((dayItem) => {
              if (dayItem.day === day) {
                return {
                  ...dayItem,
                  periods: [...dayItem.periods, { startTime: '', endTime: '' }]
                };
              }
              return dayItem;
            })
          };
        }
        return locationItem;
      })
    );
    setAvailabilityChanged(true);
  };
  const removeTimePeriod = (locationId, dayIndex, periodIndex) => {
    setLocations((prevAvailability) =>
      prevAvailability.map((locationItem) => {
        if (locationItem.id === locationId) {
          return {
            ...locationItem,
            availability: locationItem.availability.map((dayItem, idx) => {
              if (idx === dayIndex) {
                const updatedPeriods = dayItem.periods.filter(
                  (period, pIdx) => pIdx !== periodIndex
                );
                return { ...dayItem, periods: updatedPeriods };
              }
              return dayItem;
            })
          };
        }
        return locationItem;
      })
    );
    setAvailabilityChanged(true);
  };

  const handleTimeChange = (locationId, day, periodIndex, type, value) => {
    setLocations((prev) => {
      return prev.map((item) => {
        if (item.id === locationId) {
          // if (item.dayAndTime.some((dayItem) => dayItem.day === day)) {
          const updatedDayAndTime = item.availability.map((dayItem) => {
            if (dayItem.day === day) {
              const periods = dayItem.periods.map((period, index) => {
                if (index === periodIndex) {
                  // Validation for the current time period
                  if (type === 'startTime') {
                    const endTime = period.endTime;
                    if (endTime && value === endTime && value !== '12:00 AM') {
                      toast.error('Start time and end time cannot be the same');
                      return period;
                    }
                    if (
                      endTime &&
                      timeSlotOption.indexOf(value) > timeSlotOption1.indexOf(endTime)
                    ) {
                      toast.error('Start time cannot be later than end time');
                      return period;
                    }
                  } else if (type === 'endTime') {
                    const startTime = period.startTime;

                    if (startTime && value === startTime && value !== '12:00 AM') {
                      toast.error('End time and start time cannot be the same');
                      return period;
                    }
                    if (
                      startTime &&
                      timeSlotOption1.indexOf(value) < timeSlotOption.indexOf(startTime)
                    ) {
                      toast.error('End time cannot be earlier than start time');
                      return period;
                    }
                  }

                  return { ...period, [type]: value };
                }
                return period;
              });

              // Function to check time period overlaps
              const isTimePeriodValid = (newStart, newEnd, periods, currentIndex) => {
                return periods.some((period, idx) => {
                  if (idx === currentIndex) return false;

                  const existingStart = timeSlotOption.indexOf(period.startTime);
                  const existingEnd = timeSlotOption1.indexOf(period.endTime);
                  const startIdx = timeSlotOption.indexOf(newStart);
                  const endIdx = timeSlotOption1.indexOf(newEnd);

                  const isStartOverlapping =
                    newStart && startIdx >= existingStart && startIdx <= existingEnd;

                  const isEndOverlapping =
                    newEnd && endIdx >= existingStart && endIdx <= existingEnd;

                  const isTimePeriodOverlapping =
                    newStart && newEnd && startIdx <= existingStart && endIdx >= existingEnd;

                  return isStartOverlapping || isEndOverlapping || isTimePeriodOverlapping;
                });
              };

              if (dayItem.periods.length > 0) {
                const currentPeriod = periods[periodIndex];
                if (currentPeriod.startTime) {
                  const otherPeriods = periods.filter((_, idx) => idx !== periodIndex);

                  if (isTimePeriodValid(currentPeriod.startTime, null, otherPeriods, periodIndex)) {
                    toast.error('The start time overlaps with an existing time period.');
                    return dayItem;
                  }
                }

                if (currentPeriod.endTime) {
                  if (
                    isTimePeriodValid(
                      currentPeriod.startTime,
                      currentPeriod.endTime,
                      periods,
                      periodIndex
                    )
                  ) {
                    toast.error('The end time overlaps with an existing time period.');
                    return dayItem;
                  }
                }

                if (currentPeriod.startTime && currentPeriod.endTime) {
                  // after all validations, sort the periods by start time
                  dayItem.periods = periods.sort((a, b) => {
                    const startA = timeSlotOption.indexOf(a.startTime);
                    const startB = timeSlotOption.indexOf(b.startTime);

                    return startA - startB;
                  });
                  return dayItem;
                }
              }
              return { ...dayItem, periods };
            }
            return dayItem;
          });
          return { ...item, availability: updatedDayAndTime };
        }
        return item;
      });
    });
    setAvailabilityChanged(true);
  };

  return (
    <div className="flex-container margin-bottom--md day-div">
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive}
              onChange={() => handleDayChange(id, day)}
              // onChange={onDayChange}
            />
          }
          label={dayName}
        />
      </div>
      <div className="timeslot-outer-div">
        {!isActive && <p style={{ marginTop: '18px' }}>Unavailable</p>}
        {isActive &&
          periods.map((period, index) => (
            <div key={index} className="time-period margin-top--sm">
              <FormControl style={{ marginRight: '10px' }} size="small">
                <InputLabel>From *</InputLabel>
                <Select
                  required
                  size="small"
                  label="From"
                  value={period.startTime}
                  onChange={(e) => handleTimeChange(id, day, index, 'startTime', e.target.value)}
                  style={{ minWidth: '120px' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300
                      }
                    }
                  }}>
                  {timeSlotOption.map((timeSlot) => (
                    <MenuItem key={timeSlot} value={timeSlot}>
                      {timeSlot}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel>To *</InputLabel>
                <Select
                  required
                  value={period.endTime}
                  onChange={(e) => handleTimeChange(id, day, index, 'endTime', e.target.value)}
                  label="To"
                  size="small"
                  style={{ minWidth: '120px' }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300
                      }
                    }
                  }}>
                  {timeSlotOption1.map((timeSlot) => (
                    <MenuItem key={timeSlot} value={timeSlot}>
                      {timeSlot}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton
                disabled={periods.length === 1}
                onClick={() => removeTimePeriod(id, day, index)}>
                <DeleteIcon />
              </IconButton>

              {index === periods.length - 1 && (
                <IconButton onClick={() => addTimePeriod(id, day)}>
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              )}
            </div>
          ))}
      </div>

      <style jsx>{`
        .day-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 0px 0px 15px 0px;
          justify-content: space-between;
        }
        .timeslot-outer-div {
          margin-right: 30%;
          margin-top: -10px;
        }
        // .first-timeslot {
        //   // margin-right: 10px;
        // }
        .top-margin {
          margin-top: 12px;
        }
        @media only screen and (max-width: 765px) {
          .timeslot-outer-div {
            margin-right: 0%;
          }
        }
        @media only screen and (max-width: 500px) {
          .day-div {
            min-height: 85px;
            // text-align: center;
            flex-direction: column;
            justify-content: center;
            align-item: center;
            align-content: center;
          }
        }
        @media only screen and (max-width: 360px) {
          .timeslot-inner-div {
            display: flex;
            // flex-direction: column;
          }
          // .first-timeslot {
          //   margin-right: 0px;
          // }
        }
      `}</style>
    </div>
  );
};

export default DayItem;
