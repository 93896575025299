import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { database } from '../../../../helper/firebase.helper';
import { doc, getDoc } from 'firebase/firestore';
import { useStoreState } from 'easy-peasy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const IntakeTasks = () => {
  const { id: clientId } = useParams();

  const [clientData, setClientData] = useState();
  const [clientFields, setClientFields] = useState();
  const [orgCustomFields, setOrgCustomFields] = useState();
  const user = useStoreState((state) => state.user);

  const groupFieldsBySection = (fields) => {
    return fields.reduce((acc, field) => {
      const section = field.section || 'Other';
      if (!acc[section]) {
        acc[section] = [];
      }
      acc[section].push(field);
      return acc;
    }, {});
  };

  useEffect(() => {
    getDoc(doc(database, 'clients', clientId))
      .then((res) => {
        setClientData({ id: res.id, ...res.data() });
      })
      .catch((err) => {
        toast.error(`failed to get client profile: ${err.message}`);
      });
  }, [clientId]);

  useEffect(() => {
    getDoc(doc(database, 'clients', clientId, 'details', 'custom_fields'))
      .then((res) => {
        setClientFields(res.data());
      })
      .catch((err) => {
        toast.error(`failed to get client data: ${err.message}`);
      });
  }, [clientId]);

  useEffect(() => {
    if (user) {
      getDoc(doc(database, 'organisations', user.orgId, 'details', 'custom_fields'))
        .then((res) => {
          setOrgCustomFields(groupFieldsBySection(res.data().fields));
        })
        .catch((err) => {
          toast.error(`failed to get org data: ${err.message}`);
        });
    }
  }, [user]);

  return (
    <div className="client-profile margin-bottom--md">
      <h4 className="margin-top--sm margin-bottom--xs ">{clientData?.name} - Profile</h4>
      <hr></hr>
      <div className="fields-row ">
        <div className="field-item margin-bottom--md">
          <p>Email</p>
          <p className="field-data">{clientData?.email}</p>
        </div>
        {clientFields && orgCustomFields
          ? Object.keys(orgCustomFields).map((section) => {
              const sectionHasMissingMandatory = orgCustomFields[section].some(
                (field) => field.mandatory && !clientFields[field.id]
              );

              return (
                <Accordion
                  key={section}
                  style={{
                    marginTop: '20px',
                    borderRadius: '3px'
                  }}
                  sx={{
                    '&:before': {
                      display: 'none'
                    }
                  }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{ width: '100%' }}>
                      <h4 className="margin-top--xxxs" style={{ fontWeight: '600' }}>
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                      </h4>
                      {sectionHasMissingMandatory && (
                        <p className="form-completion-status">
                          <ErrorIcon color="error" style={{ marginBottom: '-6px' }} />
                          &nbsp; Mandatory fields are empty!
                        </p>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex-container fields-row">
                      {orgCustomFields[section].map((field) => (
                        <div key={field.id} className="field-item margin-bottom--md">
                          <p
                            className=""
                            style={{
                              color: field.mandatory && !clientFields[field.id] ? 'red' : null
                            }}>
                            {field.name}
                            {field.mandatory ? '*' : null}
                          </p>
                          <p className="field-data">
                            {field.type === 3 && clientFields[field.id]
                              ? new Date(clientFields[field.id]).toDateString()
                              : clientFields[field.id] || `NA`}
                          </p>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
      </div>
      <style jsx>{`
        .client-profile .field-item {
          flex-basis: 32%;
        }
        .client-profile .field-data {
          color: black;
        }
        .client-profile .fields-row {
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .form-completion-status {
          background-color: #fff4f1;
          padding: 10px 5px;
          border-radius: 5px;
        }
        @media only screen and (max-width: 900px) {
          .client-profile .field-item {
            flex-basis: 90%;
          }
        }
      `}</style>
    </div>
  );
};

export default IntakeTasks;
