import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
  useParams
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ClientHistory from './client-history/ClientHistory';
import ClientNotes from './client-history/client-notes/ClientNotes';
import IntakeTasks from './client-history/intake-tasks/IntakeTasks';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import ClientList from './client-list/ClientList';

const Clients = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const match = location.pathname.match(/\/clients\/([^/]+)/);
  const id = match ? match[1] : null;

  const clients = useMatch('/dashboard/clients');

  return (
    <>
      <Helmet>
        <title>Clients - DeTalks</title>
      </Helmet>
      <div style={{ padding: 'var(--space-unit)' }}>
        <Breadcrumbs aria-label="breadcrumb">
          {!clients && (
            <Link
              underline="hover"
              color="inherit"
              onClick={() => handleNavigation('/dashboard/clients')}>
              Clients
            </Link>
          )}

          {id && location.pathname.includes('/clients/') && (
            <Link
              underline="hover"
              color="inherit"
              onClick={() => handleNavigation(`/dashboard/clients/${id}`)}>
              {id}
            </Link>
          )}

          {location.pathname.includes('/notes') && (
            <Typography color="text.primary">Notes</Typography>
          )}
          {location.pathname.includes('/intake-tasks') && (
            <Typography color="text.primary">Intake Tasks</Typography>
          )}
        </Breadcrumbs>
        <Routes>
          {/* <Route index element={<Navigate to="" replace />} /> */}
          <Route path="/" element={<ClientList />} />
          <Route path="/:id/*" element={<ClientHistory />} />
          <Route path="/:id/notes/:activityId" element={<ClientNotes />} />
          <Route path="/:id/intake-tasks" element={<IntakeTasks />} />
        </Routes>
      </div>
    </>
  );
};

export default Clients;
