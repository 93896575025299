import { doc, getDoc, setDoc } from 'firebase/firestore';
import { database, storage } from '../../../helper/firebase.helper';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

export const getBrandingDataFromDb = async (orgId) => {
  const orgRef = doc(database, `organisations/${orgId}`);
  const orgDocSnap = await getDoc(orgRef);
  return {
    name: orgDocSnap.get('name'),
    logo: orgDocSnap.get('logo')
  };
};

export const storeBrandingToDb = async (orgId, brandingData) => {
  if (brandingData?.newLogo) {
    const storageRef = ref(storage, `organisations/${orgId}/logo/${brandingData.newLogo.name}`);
    await uploadBytes(storageRef, brandingData.newLogo).then(() => {
      //   toast.success("Image uploaded");
    });
    brandingData.logo = await getDownloadURL(storageRef).then((url) => {
      return url;
    });
    delete brandingData.newLogo;
  }

  const orgDocRef = doc(database, `organisations/${orgId}`);

  return await setDoc(orgDocRef, brandingData, { merge: true });
};
