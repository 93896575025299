import { Backdrop } from '@mui/material';
import logo from '../../../assets/detalksicon.png';

const Loader = () => {
  return (
    <Backdrop style={{ zIndex: 99999 }} open>
      <div className="container">
        <div className="logo-container">
          <img src={logo} alt="PracticeID" />
        </div>
        <div className="triple-spinner" />
        <style jsx>{`
          .container {
            width: 100%;
            max-width: 125px;
            aspect-ratio: 1 / 1;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .logo-container {
            position: absolute;
            width: 35%;
          }
        `}</style>
      </div>
    </Backdrop>
  );
};

export default Loader;
