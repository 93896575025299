import { Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../../helper/firebase.helper';
import { useStoreState } from 'easy-peasy';

const AddFiles = ({ filesToUpload, setFilesToUpload, signed, id, notes, setNotes }) => {
  const user = useStoreState((state) => state.user);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.files[0]?.size > 5242880) {
        toast.error(`File size shouldn't exceed 5MB`);
        event.target.files = '';
        return;
      } else {
        const timestamp = Date.now();
        const notesAttachmentRef = ref(
          storage,
          `users/${user.uid}/client_notes/${id}/${file.name}.${timestamp}`
        );
        await uploadBytes(notesAttachmentRef, file);
        const notesAttachmentURL = await getDownloadURL(notesAttachmentRef);
        setNotes((prevNotes) => ({
          ...prevNotes,
          files: [...prevNotes.files, { name: file.name, url: notesAttachmentURL }]
        }));
        toast.success('File attached successfully');
        // setFilesToUpload((prevFiles) => [
        //   ...prevFiles,
        //   { file: file, fileName: `${file.name}.${timestamp}` },
        // ]);
        event.target.value = '';
      }
    }
  };
  const handleDeleteFile = async (url) => {
    // setFilesToUpload((prev) =>
    //   prev.filter((file) => file.fileName !== fileName)
    // );
    try {
      const fileRef = ref(storage, url);
      await deleteObject(fileRef);
      setNotes((prevNotes) => ({
        ...prevNotes,
        files: prevNotes.files.filter((file) => file.url !== url)
      }));
      toast.success('File deleted successfully');
    } catch (error) {
      toast.error('Error deleting file:', error.message);
    }
  };
  return (
    <div className="margin-top--md">
      {notes?.files?.length > 0 && (
        <div>
          {notes?.files?.map((file, index) => (
            <p
              key={index}
              className="flex-container justify-between margin-top--xs"
              style={{
                backgroundColor: ' #f2f2f2',
                justifyContent: 'space-between',
                maxWidth: '400px',
                // alignItems: "center",
                padding: signed ? '5px 10px' : '2px 10px',
                borderRadius: '5px'
              }}>
              <a href={file.url} target="_blank" rel="noreferrer">
                <span style={{ color: '#0884ff' }}> {file.name}</span>
              </a>
              {!signed && (
                <IconButton size="small" onClick={() => handleDeleteFile(file.url)}>
                  <CloseIcon style={{ fontSize: '18px' }} />
                </IconButton>
              )}
            </p>
          ))}
        </div>
      )}
      <input
        type="file"
        multiple
        accept="application/pdf/image"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="file-upload"
      />
      <label htmlFor="file-upload">
        {!signed && (
          <Button
            disabled={signed}
            startIcon={<AttachFileIcon />}
            component="span"
            style={{ marginTop: '10px' }}>
            Attach file
          </Button>
        )}
      </label>
    </div>
  );
};

export default AddFiles;
