import React from 'react';
import { useFieldArray } from 'react-hook-form';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ArrayInput from '../../../Shared/components/array-input/ArrayInput';

const WorksWith = ({ control }) => {
  const {
    fields: worksWith,
    append: addWorksWith,
    remove: removeWorksWith
  } = useFieldArray({
    control,
    name: 'worksWith'
  });
  return (
    <>
      <div className="heading-div">
        <b>Works with</b>
      </div>
      <div className="form-row flex-container">
        <div className="field margin-bottom--xs margin-top--md">
          <ArrayInput
            name="Works with"
            addInput={addWorksWith}
            removeInput={removeWorksWith}
            fields={worksWith}
            placeholder="Children, Adolescents, Adults..."
            Icon={PeopleAltOutlinedIcon}
          />
        </div>
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }

        .form-row {
          justify-content: space-between;
          flex-wrap: wrap;
        }
        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default WorksWith;
