import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, database } from '../../../../../helper/firebase.helper';

export const getDateTime = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  let formattedDateTime = date.toLocaleString(undefined, optionsDate);

  const timezone = new Date().toLocaleDateString(undefined, {
    timeZoneName: 'long'
  });
  const timezoneParts = timezone.split(', ');
  let userTimeZone = timezoneParts.length > 1 ? timezoneParts[1] : timezoneParts[0];
  userTimeZone = userTimeZone
    .split(' ')
    .map((word) => word[0].toUpperCase())
    .join('');

  formattedDateTime += ` ${userTimeZone}`;

  return formattedDateTime;
};

export const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const cleanContent = (content) => {
  const sanitizedContent = content
    .replace(/<p><br><\/p>/g, '')
    .replace(/<p>\s*<\/p>/g, '')
    .replace(/<br>/g, '')
    .replace(/&nbsp;/g, '');
  return sanitizedContent.trim();
};

export const getNotesData = async (activityId) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const notesDocRef = doc(database, `notes/${activityId}`);
  const notesDocSnap = await getDoc(notesDocRef);

  if (notesDocSnap.exists()) {
    return notesDocSnap.data();
  } else {
    console.log('No such document!');
    return null;
  }
};

export const saveNotesToDb = async (activityId, data) => {
  const notesDocRef = doc(database, `notes/${activityId}`);
  return await setDoc(notesDocRef, data);
};
