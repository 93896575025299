import React, { useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import List from './List';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ClientList = () => {
  const [refresh, setRefresh] = useState(false);
  const [showStatusIcons, setShowStatusIcons] = useState(false);
  const [sortOption, setSortOption] = useState(1);
  const [showClientIcons, setShowClientIcons] = useState(false);
  const [showArrowIcons, setShowArrowIcons] = useState('status');

  const handleSortClick = (option) => {
    setSortOption(option);
    setRefresh(!refresh);
  };

  return (
    <div className="history-wrapper margin-top--sm">
      <div className="flex-container text--md  outer-container">
        <div
          className="email-div"
          onMouseEnter={() => setShowClientIcons(true)}
          onMouseLeave={() => setShowClientIcons(false)}
          onClick={() => {
            setShowArrowIcons('client');
          }}>
          <p className="heading-color">
            Client
            {(showArrowIcons === 'client' || showClientIcons) && (
              <>
                {sortOption === 3 ? (
                  <ArrowUpwardIcon
                    style={{ marginBottom: '-6px', marginLeft: '10px' }}
                    onClick={() => {
                      handleSortClick(4);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ marginBottom: '-6px', marginLeft: '10px' }}
                    onClick={() => {
                      handleSortClick(3);
                    }}
                  />
                )}
              </>
            )}
          </p>
        </div>
        {/* <div className="icon-div"></div> */}
        <div className="contact-div">
          <p className="heading-color">Latest Activity</p>
        </div>

        <div
          className="status-div flex-container "
          onMouseEnter={() => setShowStatusIcons(true)}
          onMouseLeave={() => setShowStatusIcons(false)}
          onClick={() => {
            setShowArrowIcons('status');
          }}>
          <p className="heading-color name-div-heading">
            Status
            {(showArrowIcons === 'status' || showStatusIcons) && (
              <>
                {sortOption === 2 ? (
                  <ArrowUpwardIcon
                    style={{ marginBottom: '-6px', marginLeft: '10px' }}
                    onClick={() => {
                      handleSortClick(1);
                    }}
                  />
                ) : (
                  <ArrowDownwardIcon
                    style={{ marginBottom: '-6px', marginLeft: '10px' }}
                    onClick={() => {
                      handleSortClick(2);
                    }}
                  />
                )}
              </>
            )}
          </p>
        </div>
        <div className="refresh-icon-div flex-container">
          <span onClick={() => setRefresh(!refresh)}>
            <RefreshIcon color="primary" style={{ cursor: 'pointer', marginRight: '8px' }} />
          </span>
        </div>
      </div>
      <List key={refresh} sortOption={sortOption} />

      <style jsx>
        {`
          .history-wrapper {
            min-height: 90vh;
            // padding: 20px;
          }
          // .icon-div {
          //   width: 4%;
          // }
          .email-div {
            width: 30%;
            overflow: hidden;
          }
          .contact-div {
            width: 20%;
          }

          .status-div {
            width: 30%;
            justify-content: space-between;
          }
          .heading-color {
            color: var(--gray-light);
          }
          .outer-container {
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
            justify-content: space-between;
          }
          .inner-div {
            color: var(--gray-darkest);
          }
          .refresh-icon-div {
            width: 5%;
            justify-content: right;
          }
          @media only screen and (max-width: 900px) {
            .email-div {
              width: 70%;
            }
            .contact-div {
              display: none;
            }
            .name-div-heading {
              display: none;
            }

            .status-div {
              display: none;
              // width: 20%;
              // justify-content: right;
            }
          }
          @media only screen and (max-width: 530px) {
            .history-wrapper {
              // padding: 10px 0px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ClientList;
