import React, { useState } from 'react';
import { InputAdornment } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Button, TextField } from '@mui/material';

const ArrayInput = ({ addInput, removeInput, name, fields, placeholder, Icon }) => {
  const [input, setInput] = useState('');

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleAddInput = () => {
    const inputArray = input
      .split(',')
      .map((word) => word.trim())
      .filter((word) => word !== '');

    inputArray.forEach((word) => addInput({ text: word }));
    setInput('');
  };

  const handleDelete = (index) => {
    removeInput(index);
  };

  return (
    <div>
      <div className="field-container flex-container margin-bottom--sm">
        <TextField
          label={name}
          fullWidth
          placeholder={placeholder}
          value={input}
          onChange={handleInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon color="primary" />
              </InputAdornment>
            )
          }}
        />
        <div className="add-button">
          <Button disabled={!input} size="small" variant="contained" onClick={handleAddInput}>
            Add
          </Button>
        </div>
      </div>
      <div className="flex-container chip-wrapper">
        {fields.map((field, index) => (
          <div key={field.id} className="chip-container margin-top--xs margin-bottom--xs">
            <Chip label={field.text} onDelete={() => handleDelete(index)} />
          </div>
        ))}
      </div>
      <style jsx>{`
        .add-button {
          margin-left: 5px;
          margin-top: 10px;
        }
        .field-container {
          // max-width: 450px;
        }
        .chip-container {
          margin-right: 5px;
        }
        .chip-wrapper {
          flex-wrap: wrap;
        }
      `}</style>
    </div>
  );
};

export default ArrayInput;
