import React from 'react';
import { schedule } from '../../../helper/data.helper';
import { Accordion, AccordionSummary, AccordionDetails, Button } from '@mui/material';
import { getDate, getDay, getMonthYear } from '../bookings.service';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MyBookings = () => {
  const timeToMinutes = (time, isEndTime = false) => {
    const [timeString, modifier] = time.split(' ');
    let [hours, minutes] = timeString.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    } else if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    if (isEndTime && modifier === 'AM' && hours === 0 && minutes === 0) {
      return 1440;
    }

    return hours * 60 + minutes;
  };

  const calculateWidthPercentage = (startTime, endTime) => {
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime, true);
    const durationInMinutes = endMinutes - startMinutes;
    return (durationInMinutes / 1440) * 100;
  };

  const calculateLeftPercentage = (startTime) => {
    const startMinutes = timeToMinutes(startTime);
    return (startMinutes / 1440) * 100;
  };

  return (
    <div className="bookings-container ">
      {schedule &&
        schedule.length > 0 &&
        schedule?.map((day, i) => (
          <div key={i} className="accordion-container">
            <Accordion
              defaultExpanded={new Date(day.date).getDate() === new Date().getDate()}
              style={{ marginTop: '30px' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                style={{
                  padding: window.innerWidth > 550 ? '0px 5px 0px 20px' : '0px 3px 0px 10px'
                }}>
                <div
                  style={{
                    width: '100%'
                  }}>
                  <div
                    className="flex-container"
                    style={{
                      display: 'flex',
                      width: '100%'
                    }}>
                    <div className="date-icon flex-container align-center justify-center">
                      <p style={{ marginTop: '-1px', textAlign: 'center' }}>{getDate(day?.date)}</p>
                    </div>
                    <div style={{ marginLeft: '20px', textAlign: 'right' }}>
                      <p>{getMonthYear(day?.date)}</p>
                      <p>{getDay(day?.date)}</p>
                    </div>
                    <div
                      style={{
                        borderLeft: '1px solid var(--gray-lightest)',
                        marginLeft: '10px',
                        paddingLeft: '10px',
                        flexGrow: 1
                      }}>
                      <div className="flex-container bookings-count-container">
                        <div>{day?.bookings?.length} Bookings &nbsp;</div>
                        <div>
                          ({window.innerWidth > 550 && ' Starts at '}
                          {day?.bookings[0]?.startTime}
                          {window.innerWidth < 550 && ' - '}
                          {window.innerWidth > 550 && ' ends at '}
                          {day?.bookings[day?.bookings?.length - 1]?.endTime})
                        </div>
                      </div>
                      {window.innerWidth > 550 && (
                        <>
                          <div className="timeline-container margin-top--xs">
                            {day?.bookings?.map((booking, i) => (
                              <div
                                key={i}
                                className="booking-slot"
                                style={{
                                  left: `${calculateLeftPercentage(booking.startTime)}%`,
                                  width: `${calculateWidthPercentage(
                                    booking.startTime,
                                    booking.endTime
                                  )}%`
                                }}></div>
                            ))}
                          </div>
                          <hr
                            style={{
                              margin: '0px',
                              marginTop: '-5.4px',
                              zIndex: 9,
                              border: 'none',
                              height: '1px',
                              backgroundImage:
                                'radial-gradient(circle, var(--border-gray) 1px, transparent 1px)',
                              backgroundSize: '6px 1px',
                              backgroundRepeat: 'repeat-x'
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={window.innerWidth < 551 ? 'margin-bottom--xxxs' : ''}>
                    {window.innerWidth < 551 && (
                      <>
                        <div className="timeline-container " style={{ marginTop: '10px' }}>
                          {day?.bookings?.map((booking, i) => (
                            <div
                              key={i}
                              className="booking-slot"
                              style={{
                                left: `${calculateLeftPercentage(booking.startTime)}%`,
                                width: `${calculateWidthPercentage(
                                  booking.startTime,
                                  booking.endTime
                                )}%`
                              }}></div>
                          ))}
                        </div>
                        <hr
                          style={{
                            margin: '0px',
                            marginTop: '-5.4px',
                            zIndex: 9,
                            border: 'none',
                            height: '1px',
                            backgroundImage:
                              'radial-gradient(circle, var(--border-gray) 1px, transparent 1px)',
                            backgroundSize: '6px 1px',
                            backgroundRepeat: 'repeat-x'
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails style={{ padding: '5px 0px' }}>
                {day?.bookings?.map((booking, i) => (
                  <div key={i} className="booking-details-container flex-container justify-between">
                    <div className="details-container flex-container justify-between">
                      <div className="meeting-details">
                        <p>
                          {booking.startTime} - {booking.endTime}{' '}
                        </p>
                        <p>{booking.title}</p>
                      </div>
                      <div className="client-details">
                        <p>{booking.client_name}</p>
                        <p> {booking.client_email}</p>
                        <p>{booking.client_contact}</p>
                      </div>
                      <div className="location-details">
                        <p> {booking.location}</p>
                        <p>{booking.fees}</p>
                      </div>
                    </div>
                    <div className="btn-container flex-container direction-column">
                      <Button variant="contained" color="primary" size="small">
                        Join meeting
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        style={{
                          justifyContent: 'flex-start',
                          textAlign: 'left'
                        }}>
                        Add notes
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        style={{
                          justifyContent: 'flex-start',
                          textAlign: 'left'
                        }}>
                        Reschedule meeting
                      </Button>
                      <Button
                        color="primary"
                        size="small"
                        style={{
                          justifyContent: 'flex-start',
                          textAlign: 'left'
                        }}>
                        Cancel meeting
                      </Button>
                    </div>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      {!schedule && schedule.length < 1 && <p>No bookings yet !</p>}
      <style jsx>{`
        .bookings-container {
          margin-top: -10px;
          width: 100%;
        }
        .accordion-container {
          margin: auto;
          width: 98%;
        }
        .date-icon {
          width: 38px;
          height: 38px;
          background-color: #007aff;
          color: white;
          border-radius: 50%;
          font-size: 20px;
        }
        .timeline-container {
          position: relative;
          width: 100%;
          height: 10px;
        }
        .booking-slot {
          position: absolute;
          height: 10px;
          background-color: #007aff;
          border-radius: 5px;
        }
        .booking-details-container {
          border-top: 1px solid var(--border-gray);
          padding: 15px 10px 15px 50px;
        }
        .details-container {
          width: 70%;
          flex-wrap: wrap;
        }

        .client-details p,
        .meeting-details p,
        .location-details p {
          word-wrap: break-word;
          overflow-wrap: break-word;
          word-break: break-all;
        }
        .meeting-details {
          width: 32%;
        }
        .client-details {
          width: 32%;
        }
        .location-details {
          width: 32%;
        }
        .btn-container {
          width: 25%;
          max-width: 200px;
        }
        @media only screen and (max-width: 950px) {
          .booking-details-container {
            padding: 15px 10px 15px 10px;
          }
          .details-container {
            flex-direction: column;
          }
          .meeting-details {
            width: 95%;
          }
          .client-details {
            width: 95%;
            margin-top: 20px;
          }
          .location-details {
            width: 95%;
            margin-top: 20px;
          }
          .details-container {
            width: 60%;
          }
          .btn-container {
            width: 40%;
            max-width: 200px;
          }
        }
        @media only screen and (max-width: 550px) {
          .bookings-count-container {
            flex-direction: column;
          }
        }
      `}</style>
    </div>
  );
};

export default MyBookings;
