import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { experienceOptions } from '../shared/profile.service';
import Proof from '../shared/Proof';

const Experience = ({ control, setValue, getValues, verified }) => {
  const [experienceProofUrl, setExperienceProofUrl] = useState('');

  const watchedExpProofUrl = useWatch({
    control,
    name: 'experience.proofUrl'
  });

  useEffect(() => {
    const storedProofUrl = getValues('experience.proofUrl');
    if (storedProofUrl) {
      setExperienceProofUrl(storedProofUrl);
    }
  }, [getValues, watchedExpProofUrl, setValue]);

  const handleExpProofUpload = (file) => {
    setValue('experienceProof', { file: file });
  };

  const deleteProofFile = async () => {
    if (!verified) {
      const url = await getValues('experience.proofUrl');
      setValue('deleteExperienceProof', url);
    } else {
      setValue('experienceProof', null);
    }
    setValue('experience.proofUrl', '');
    setExperienceProofUrl('');
  };

  return (
    <>
      <div className="heading-div ">
        <b>Experience</b>
      </div>
      <div className=" flex-container direction-column">
        <div style={{ width: '300px' }} className="flex-container field margin-top--md">
          <Controller
            name="experience.value"
            control={control}
            defaultValue={0}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={!!error}
                required={verified}>
                <InputLabel id="experience-label">Experience</InputLabel>
                <Select
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 300
                      }
                    }
                  }}
                  // sx={{ maxWidth: 300 }}
                  labelId="experience-label"
                  value={value}
                  onChange={onChange}
                  label="Experience"
                  startAdornment={
                    <InputAdornment position="start">
                      <TimelineOutlinedIcon color="primary" />
                    </InputAdornment>
                  }
                  renderValue={(selected) => (
                    <Typography>
                      {selected === 0 ? `${selected} Years` : `${selected}+ Years`}
                    </Typography>
                  )}>
                  {experienceOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                {error && <TextField error helperText={error.message} />}
              </FormControl>
            )}
            rules={verified ? { required: 'Please fill this field' } : {}}
          />
        </div>
        <Proof
          existingUrl={experienceProofUrl}
          path="experience"
          onUpload={handleExpProofUpload}
          deleteProofFile={deleteProofFile}
        />
      </div>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }

        .field {
          width: 49%;
          min-width: 300px;
          position: relative;
          align-items: center;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Experience;
