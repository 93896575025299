import { getIdToken } from 'firebase/auth';
import { auth } from './firebase.helper';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://127.0.0.1:5001/detalks-test-23af7/asia-south1/api/';

export const makePost = async (url, data, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const makeGet = async (url, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const registerPractitioner = (data) => {
  const url = `register/practitioner`;
  return makePost(url, data);
};

export const invitePractitioner = (data) => {
  const url = `practitioner/account/invite`;
  return makePost(url, data);
};

export const resendGroupPracticeInvite = (data) => {
  const url = `practitioner/account/resend-invite`;
  return makePost(url, data);
};

export const getAllServices = () => {
  const url = `practitioner/services`;
  return makeGet(url);
};
