import React from 'react';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import PsychologyIcon from '@mui/icons-material/Psychology';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const MeetingDetails = ({ id, show, handleShowNameChange, clientData }) => {
  return (
    <>
      <div className="meeting-details flex-container margin-top--md">
        <div style={{ marginRight: '50px' }}>
          <p className="detail-item flex-container">
            <FingerprintIcon color="primary" style={{ marginRight: '10px' }} />
            {id}
          </p>
          <p className="detail-item flex-container" style={{ display: show ? 'flex' : 'none' }}>
            <PersonIcon color="primary" style={{ marginRight: '10px' }} />
            {clientData?.name}
          </p>
          <p className="detail-item flex-container" style={{ display: show ? 'flex' : 'none' }}>
            <EmailIcon color="primary" style={{ marginRight: '10px' }} />
            {clientData?.email}
          </p>
          <p
            className="flex-container direction-column margin-top--sm"
            style={{ width: '100%', marginBottom: '15px', maxWidth: '295px' }}>
            <p style={{ marginLeft: '50px', zIndex: 9 }}>
              {show ? (
                <span
                  onClick={handleShowNameChange}
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: '0px 8px',
                    cursor: 'pointer'
                  }}>
                  <VisibilityOffIcon style={{ marginBottom: '-7px', marginRight: '5px' }} />
                  Hide
                </span>
              ) : (
                <span
                  onClick={handleShowNameChange}
                  style={{
                    backgroundColor: '#f2f2f2',
                    padding: '0px 8px',
                    cursor: 'pointer'
                  }}>
                  <VisibilityIcon style={{ marginBottom: '-7px', marginRight: '5px' }} />
                  Show
                </span>
              )}
            </p>
            <p style={{ borderTop: '2px solid lightgrey', marginTop: '-9px' }} />
          </p>
        </div>
        <div>
          <p className="detail-item flex-container">
            <EventIcon color="primary" style={{ marginRight: '10px' }} />
            meeting time
          </p>
          <p className="detail-item flex-container">
            <PsychologyIcon
              color="primary"
              style={{ transform: 'scaleX(-1)', marginRight: '10px' }}
            />
            service&nbsp;
            <span
              style={{
                backgroundColor: 'rgb(216 230 253)',
                padding: '0px 6px',
                borderRadius: '5px'
              }}>
              5 min
            </span>
          </p>
          <p className="detail-item flex-container">
            <LocationOnIcon color="primary" style={{ marginRight: '10px' }} />
            meeting location
          </p>
        </div>
      </div>
      <style jsx>
        {`
          .meeting-details {
            // flex-wrap: wrap;
            // justify-content: space-between;
            max-width: 800px;
          }
          .detail-item {
            // flex-basis: 45%;
            white-space: normal;
            // word-break: break-word;
            margin-top: 10px;
            align-items: center;
          }

          @media only screen and (max-width: 800px) {
            .meeting-details {
              flex-direction: column;
            }
          }
        `}
      </style>
    </>
  );
};

export default MeetingDetails;
