export const tempServices = [
  {
    id: 1,
    title: 'Couple Counselling',
    time: 15,
    preEventBuffer: '5',
    postEventBuffer: '2',
    selectedLocations: [
      {
        id: 2,
        name: 'Los Angeles ',
        price: '34233'
      },
      { id: 3, name: 'Chicago', price: 1 }
    ]
  },
  {
    id: 2,
    title: 'Psychotherapy',
    time: 30,
    preEventBuffer: '1',
    postEventBuffer: '2',
    selectedLocations: [
      { id: 2, name: 'Los Angeles', price: '343' },
      { id: 3, name: 'Chicago', price: 1 }
    ]
  },
  {
    id: 3,
    title: 'ADHD',
    time: 45,
    preEventBuffer: '4',
    postEventBuffer: '6',
    selectedLocations: [{ id: 3, name: 'Chicago', price: 1 }]
  }
];

export const tempAvailability = [
  {
    id: 1,
    meetingType: 'Telehealth',
    location: 'Google Meet',
    minimumNotice: 1,
    dayAndTime: [
      {
        day: 0,
        periods: [
          { startTime: '12:15 AM', endTime: '1:15 AM' },
          { startTime: '2:15 AM', endTime: '6:30 AM' }
        ],
        isActive: true
      },
      {
        day: 1,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 2,
        periods: [
          { startTime: '11:15 AM', endTime: '1:30 PM' },
          { startTime: '2:15 PM', endTime: '6:30 PM' }
        ],
        isActive: true
      },
      {
        day: 3,
        periods: [
          { startTime: '12:15 PM', endTime: '2:15 PM' },
          { startTime: '3:15 PM', endTime: '7:00 PM' }
        ],
        isActive: true
      },
      {
        day: 4,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 5,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 6,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      }
    ]
  },
  {
    id: 2,
    meetingType: 'In-Person',
    location: 'Model gram, Ludhiana',
    locationLink: 'https://maps.app.goo.gl/L99dG3kadbUohw3o8',
    minimumNotice: 2,
    dayAndTime: [
      {
        day: 0,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 1,
        periods: [
          { startTime: '12:15 PM', endTime: '1:15 PM' },
          { startTime: '3:15 PM', endTime: '4:30 PM' }
        ],
        isActive: true
      },
      {
        day: 2,
        periods: [
          { startTime: '12:15 AM', endTime: '1:15 AM' },
          { startTime: '5:15 PM', endTime: '6:00 PM' }
        ],
        isActive: true
      },
      {
        day: 3,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 4,
        periods: [
          { startTime: '2:30 AM', endTime: '4:15 AM' },
          { startTime: '5:15 PM', endTime: '7:00 PM' }
        ],
        isActive: true
      },
      {
        day: 5,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      },
      {
        day: 6,
        periods: [{ startTime: '', endTime: '' }],
        isActive: false
      }
    ]
  }
];

export const schedule = [
  {
    date: Date.now() + 86400000,
    bookings: [
      // {
      //   startTime: "12:00 AM",
      //   endTime: "1:00 AM",
      //   title: "Psychotherapy",
      //   client_name: "JOhn Doe",
      //   client_email: "abc@abc.com",
      //   client_contact: 1234567890,
      //   location: "Google meet",
      //   fees: "$100 paid online"
      // },
      {
        startTime: '1:00 AM',
        endTime: '1:30 AM',
        title: 'Psychotherapy',
        client_name: 'John Doe',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'Google meet',
        fees: '$100 paid online'
      },
      {
        startTime: '12:15 PM',
        endTime: '12:30 PM',
        title: 'Psychotherapy',
        client_name: 'Rose',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '9:00 PM',
        endTime: '10:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      }
    ]
  },
  {
    date: Date.now(),
    bookings: [
      {
        startTime: '9:00 AM',
        endTime: '10:00 AM',
        title: 'Psychotherapy',
        client_name: 'John Doe',
        client_email: 'abc@abfhghfhgdfgdffdc.com',
        client_contact: 1234567890,
        location: 'Google meet',
        fees: '$100 paid online'
      },
      {
        startTime: '2:00 PM',
        endTime: '3:00 PM',
        title: 'Psychotherapy',
        client_name: 'Rose',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '5:00 PM',
        endTime: '7:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '11:00 PM',
        endTime: '11:45 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      }
    ]
  },
  {
    date: Date.now() - 86400000,
    bookings: [
      {
        startTime: '12:00 AM',
        endTime: '1:00 AM',
        title: 'Psychotherapy',
        client_name: 'John Doe',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'Google meet',
        fees: '$100 paid online'
      },

      {
        startTime: '4:00 AM',
        endTime: '5:00 AM',
        title: 'Psychotherapy',
        client_name: 'John Doe',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'Google meet',
        fees: '$100 paid online'
      },

      {
        startTime: '8:00 AM',
        endTime: '9:00 AM',
        title: 'Psychotherapy',
        client_name: 'Rose',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '11:00 AM',
        endTime: '12:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '1:00 PM',
        endTime: '1:40 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },

      {
        startTime: '7:00 PM',
        endTime: '8:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '10:00 PM',
        endTime: '11:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      }
    ]
  },

  {
    date: Date.now() - 86400000 * 2,
    bookings: [
      {
        startTime: '7:00 PM',
        endTime: '8:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      },
      {
        startTime: '8:00 PM',
        endTime: '9:00 PM',
        title: 'Psychotherapy',
        client_name: 'Carl Thomas',
        client_email: 'abc@abc.com',
        client_contact: 1234567890,
        location: 'New York',
        fees: 'Pay at visit'
      }
    ]
  }
];

export const peopleList = [
  {
    id: 1,
    name: 'Mike',
    email: 'Mike@gmail.com',
    updateTime: Date.now() - 86400000,
    messages: [
      { text: 'Hii', status: 'sent', time: Date.now() - 86408000, seen: true },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 86407000,
        read: true
      },
      {
        text: 'How are you?',
        status: 'sent',
        time: Date.now() - 86406000,
        seen: true
      },
      {
        text: 'I am fine I am fine I am fine I am fine!',
        status: 'received',
        time: Date.now() - 86405000,
        read: true
      },
      { text: 'Hii', status: 'sent', time: Date.now() - 86408000, seen: true },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 86407000,
        read: true
      },
      {
        text: 'How are you?',
        status: 'sent',
        time: Date.now() - 86406000,
        seen: false
      },
      {
        text: 'I am fine I am fine I am fine I am fine!',
        status: 'received',
        time: Date.now() - 86405000,
        read: true
      },
      { text: 'Hii', status: 'sent', time: Date.now() - 86408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 86407000,
        read: true
      },
      { text: 'How are you?', status: 'sent', time: Date.now() - 756000 },
      {
        text: 'I am fine I am fine I am fine I am fine! I am fine I am fine I am fine I am fine! I am fine I am fine I am fine I am fine!',
        status: 'received',
        time: Date.now() - 705000,
        read: true
      }
    ]
  },
  {
    id: 2,
    name: 'John',
    email: 'john@gmail.com',
    updateTime: Date.now() - 86600600,
    messages: [
      {
        text: 'Movie?',
        status: 'sent',
        time: Date.now() - 86608000,
        seen: true
      },
      {
        text: 'Which one ?',
        status: 'received',
        time: Date.now() - 86607000,
        read: true
      },
      {
        text: 'Iron Man',
        status: 'sent',
        time: Date.now() - 86606000,
        seen: false
      },
      {
        text: 'Lets go!',
        status: 'received',
        time: Date.now() - 86605000,
        read: true
      }
    ]
  },
  {
    id: 3,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 4,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 5,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 6,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 7,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 8,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 9,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  },
  {
    id: 10,
    name: 'Rohan',
    email: 'rohan@gmail.com',
    updateTime: Date.now() - 83400000,
    messages: [
      { text: 'Hey', status: 'sent', time: Date.now() - 83408000 },
      {
        text: 'Hii',
        status: 'received',
        time: Date.now() - 83407000,
        read: true
      },
      {
        text: 'When will we meet?',
        status: 'sent',
        time: Date.now() - 83406000
      },
      {
        text: 'today evening',
        status: 'received',
        time: Date.now() - 83405000,
        read: false
      }
    ]
  }
];
