import { Hidden } from '@mui/material';
import React from 'react';
import ReceivedInvitationsHistory from './received-invitations-history/ReceivedInvitationsHistory';
import { Helmet } from 'react-helmet';

const OtherPractices = () => {
  return (
    <div style={{ padding: 'var(--space-unit)' }} className="wrapper margin-top--sm">
      <Helmet>
        <title>Other Practices - DeTalks</title>
      </Helmet>
      <h4 className="margin-top--xxxs margin-bottom--md">Received Invitations</h4>
      <div className="flex-container header">
        <p className="item text--sm gray-text">Practitioner Email</p>
        <Hidden smDown>
          <p className="item text--sm gray-text">Practitioner Name</p>
        </Hidden>
        <Hidden smDown>
          <p className="item text--sm gray-text">Status</p>
        </Hidden>
        <div className="item text--sm gray-text flex-container actions">Actions</div>
      </div>
      <hr />
      <div>
        <ReceivedInvitationsHistory />
      </div>
      <style jsx>{`
        .header {
        }
        .item {
          width: 25%;
          flex-grow: 1;
        }
        hr {
          border: none;
          background-color: var(--gray-lightest);
          height: 1px;
        }
        .actions {
          justify-content: space-between;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default OtherPractices;
