import React, { useState } from 'react';
import InviteHistory from './inviteHistory/InviteHistory';
import { Button, InputAdornment, TextField } from '@mui/material';
import { validateEmail } from '../../../helper/util.helper';
import toast from 'react-hot-toast';
import { invitePractitioner } from '../../../helper/api.helper';
import { useStoreState } from 'easy-peasy';
import { Helmet } from 'react-helmet';

const Invites = () => {
  const practitioner = useStoreState((state) => state.user);

  const [inviteEmail, setInviteEmail] = useState('');
  const [open, setOpen] = useState(
    // !isAdmin ||
    false
  );

  const invitePract = () => {
    if (!validateEmail(inviteEmail)) {
      toast.error('Please enter valid email');
      return;
    }
    toast.loading('Inviting practitioner...');
    invitePractitioner({ email: inviteEmail })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss();
        if (res.status === 'success') {
          toast.success('Practitioner invited');
          setInviteEmail('');
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(`Failed to invite - ${err.message}`);
      });
  };

  return (
    <div>
      <Helmet>
        <title>Invites - DeTalks</title>
      </Helmet>
      {/* <Accordion Arrow={KeyboardArrowDown} rotation="180" tab={1} opened={open}> */}
      <h4 className="margin-bottom--sm margin-top--sm">
        <div className="margin-bottom--sm">Practitioner invitations</div>
        {/* {currPackage?.seats !== 1 && isAdmin ? ( */}
        <TextField
          label="Enter email address of practitioner"
          variant="outlined"
          fullWidth
          size="small"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
          onClick={() => setOpen(!open)}
          helperText="Add email address"
          // disabled={
          //   currPackage?.seats <= currPackage?.group_seats_used || !isAdmin
          // }
          onKeyPress={(e) => {
            if (e.key === 'Enter' && validateEmail(inviteEmail)) {
              invitePract();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={invitePract}
                  color="primary"
                  disabled={
                    // currPackage?.seats <= currPackage?.group_seats_used ||
                    !validateEmail(inviteEmail)
                  }>
                  Invite
                </Button>
              </InputAdornment>
            )
          }}
        />
        {/* ) : null} */}
      </h4>

      <InviteHistory />
      {/* </Accordion> */}
      <style jsx>{`
        h4 {
          border-bottom: 1px solid #d1d1d6;
          padding-bottom: 10px;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default Invites;
