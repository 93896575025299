import { AppBar, Tab, Tabs } from '@mui/material';
import React from 'react';
import Branding from './branding/Branding';
import Domain from './domain/Domain';
import Invites from './invites/Invites';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';

const MyPractice = () => {
  return (
    <>
      {window.innerWidth < 960 && (
        <AppBar position="sticky" color="inherit" elevation={2}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            aria-label="nav tabs">
            <Tab
              component={NavLink}
              label="Branding"
              value="branding"
              to="/dashboard/practice/branding"
            />
            <Tab
              component={NavLink}
              label="Domain"
              value="domain"
              to="/dashboard/practice/domain"
            />
            <Tab
              component={NavLink}
              label="Invites"
              value="invites"
              to="/dashboard/practice/invites"
            />
          </Tabs>
        </AppBar>
      )}
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route index element={<Navigate to="branding" replace />} />
          <Route path="branding/*" element={<Branding />} />
          <Route path="domain/*" element={<Domain />} />
          <Route path="invites/*" element={<Invites />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default MyPractice;
