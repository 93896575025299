import React, { useState } from 'react';
import InviteHistoryItem from './InviteHistoryItem';
import { Hidden } from '@mui/material';
import { useStoreState } from 'easy-peasy';

const InviteHistory = () => {
  const [inviteHistory, setInviteHistory] = useState([]);
  const practitioner = useStoreState((state) => state.user);
  //   const currPackage = useSelector(
  //     (state) => state.dashboardState.dashboard.currPackage
  //   );
  //   const isAdmin = practitioner?.uid === currPackage?.admin;

  //   useEffect(() => {
  //     let adminUnsubscribe;
  //     let memberUnsubscribe;
  //     if (isAdmin) {
  //       setInviteHistory([]);
  //       adminUnsubscribe = onSnapshot(
  //         query(
  //           collection(firestore, "group_practice_invites"),
  //           where("admin", "==", practitioner.uid)
  //         ),
  //         (docs) => {
  //           const invites = [];
  //           docs.forEach((snap) =>
  //             invites.push({ id: snap.id, ...snap.data() })
  //           );
  //           setInviteHistory((history) =>
  //             [
  //               ...history.filter(
  //                 (invite) => !invites.find((item) => item.id === invite.id)
  //               ),
  //               ...invites,
  //             ].sort((a, b) => b.updated_time - a.updated_time)
  //           );
  //         }
  //       );
  //       memberUnsubscribe = onSnapshot(
  //         query(
  //           collection(firestore, "group_practice_invites"),
  //           where("member_email", "==", practitioner.email)
  //         ),
  //         (docs) => {
  //           const invites = [];
  //           docs.forEach((doc) =>
  //             invites.push({ id: doc.id, ...doc.data() })
  //           );
  //           setInviteHistory((history) =>
  //             [
  //               ...history.filter(
  //                 (invite) => !invites.find((item) => item.id === invite.id)
  //               ),
  //               ...invites,
  //             ].sort((a, b) => b.updated_time - a.updated_time)
  //           );
  //         }
  //       );
  //     } else {
  //       setInviteHistory([]);
  //       memberUnsubscribe = onSnapshot(
  //         query(
  //           collection(firestore, "group_practice_invites"),
  //           where("member_email", "==", practitioner.email)
  //         ),
  //         (docs) => {
  //           const invites = [];
  //           docs.forEach((doc) =>
  //             invites.push({ id: doc.id, ...doc.data() })
  //           );
  //           setInviteHistory((history) =>
  //             [
  //               ...history.filter(
  //                 (invite) => !invites.find((item) => item.id === invite.id)
  //               ),
  //               ...invites,
  //             ].sort((a, b) => b.updated_time - a.updated_time)
  //           );
  //         }
  //       );
  //     }

  //     return () => {
  //       adminUnsubscribe && adminUnsubscribe();
  //       memberUnsubscribe && memberUnsubscribe();
  //     };
  //   }, [isAdmin]);
  return (
    <div className="wrapper margin-top--sm">
      <div className="flex-container header">
        <p className="item text--sm gray-text">Practitioner Email</p>
        <Hidden smDown>
          <p className="item text--sm gray-text">Practitioner Name</p>
        </Hidden>
        <Hidden smDown>
          <p className="item text--sm gray-text">Status</p>
        </Hidden>
        <div className="item text--sm gray-text flex-container actions">Actions</div>
      </div>
      <hr />
      <div>
        {inviteHistory.map((inviteItem) => (
          <InviteHistoryItem
            key={inviteItem.id}
            inviteItem={inviteItem}
            practitioner={practitioner}
            // isInGroupPractice={!isAdmin}
            // seatsAvailable={currPackage?.seats > currPackage?.group_seats_used}
            // usedSeats={currPackage?.group_seats_used}
          />
        ))}
      </div>
      <style jsx>{`
        .header {
        }
        .item {
          width: 25%;
          flex-grow: 1;
        }
        hr {
          border: none;
          background-color: var(--gray-lightest);
          height: 1px;
        }
        .actions {
          justify-content: space-between;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default InviteHistory;
