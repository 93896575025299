import { createStore, action } from 'easy-peasy';

const store = createStore({
  user: false,
  practitionerData: {},
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setPractitionerData: action((state, payload) => {
    state.practitionerData = payload;
  })
});

export default store;
