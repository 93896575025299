import { InputAdornment, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import { meetingTypeEnum } from '../../helper/constants.helper';

const LocationDetails = ({ availabilityItem, index, setLocations, setAvailabilityChanged }) => {
  const handleMeetingTypeChange = (index, event) => {
    const value = event.target.value;
    if (value === 'Telehealth') {
      setLocations((prev) => {
        const updatedList = [...prev];
        updatedList[index].meetingType = value;
        updatedList[index].location = 'Google Meet';
        updatedList[index].locationLink = '';
        return updatedList;
      });
    } else {
      setLocations((prev) => {
        const updatedList = [...prev];
        updatedList[index].meetingType = value;
        updatedList[index].location = '';
        updatedList[index].locationLink = '';
        return updatedList;
      });
    }
    setAvailabilityChanged(true);
  };

  const handleLocationChange = (index, event) => {
    setLocations((prev) => {
      const updatedList = [...prev];
      updatedList[index].location = event.target.value;
      return updatedList;
    });
    setAvailabilityChanged(true);
  };

  const handleLocationLinkChange = (index, event) => {
    const value = event.target.value;
    setLocations((prev) => {
      const updatedAvailability = [...prev];
      updatedAvailability[index].locationLink = value;
      return updatedAvailability;
    });
    setAvailabilityChanged(true);
  };

  const handleMinimumNoticeChange = (index, event) => {
    setLocations((prev) => {
      const updatedList = [...prev];
      updatedList[index].minimumNotice = event.target.value;
      return updatedList;
    });
    setAvailabilityChanged(true);
  };

  return (
    <div style={{ maxWidth: '500px' }}>
      <Select
        required
        size="small"
        value={availabilityItem.meetingType}
        onChange={(event) => handleMeetingTypeChange(index, event)}
        fullWidth
        style={{ marginTop: '25px' }}
        startAdornment={
          <InputAdornment position="start">
            <MeetingRoomOutlinedIcon style={{ color: 'black' }} />
          </InputAdornment>
        }>
        <MenuItem value={0}>Telehealth</MenuItem>
        <MenuItem value={1}>In-Person</MenuItem>
      </Select>

      {availabilityItem.meetingType === meetingTypeEnum.TELEHEALTH && (
        <TextField
          required
          size="small"
          label="Location"
          value="Google Meet"
          onChange={(event) => handleLocationChange(index, event)}
          fullWidth
          style={{ marginTop: '20px' }}
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PlaceOutlinedIcon style={{ color: 'black' }} />
              </InputAdornment>
            )
          }}
        />
      )}

      {availabilityItem.meetingType === meetingTypeEnum.IN_PERSON && (
        <>
          <TextField
            required
            size="small"
            label="Location"
            placeholder="Enter address here..."
            value={availabilityItem.location}
            onChange={(event) => handleLocationChange(index, event)}
            fullWidth
            style={{ marginTop: '20px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PlaceOutlinedIcon style={{ color: 'black' }} />
                </InputAdornment>
              )
            }}
          />
          <TextField
            required
            size="small"
            label="Location Link"
            value={availabilityItem.locationLink}
            onChange={(event) => handleLocationLinkChange(index, event)}
            fullWidth
            style={{ marginTop: '20px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PinDropOutlinedIcon style={{ color: 'black' }} />
                </InputAdornment>
              )
            }}
            placeholder="https://maps.app.goo.gl/izXoZAud39bFprX9A"
          />
          <Typography
            variant="caption"
            // style={{ marginTop: "3px", color: "gray" }}
          >
            Visit&nbsp;
            <Link
              href="https://www.google.com/maps"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
              color="secondary">
              https://www.google.com/maps
            </Link>
            &nbsp;to get the location link.
          </Typography>
        </>
      )}

      <TextField
        required
        size="small"
        label="Minimum Notice (hours)"
        type="number"
        value={availabilityItem.minimumNotice}
        onChange={(event) => handleMinimumNoticeChange(index, event)}
        // fullWidth
        inputProps={{ min: 0 }}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      />
    </div>
  );
};

export default LocationDetails;
