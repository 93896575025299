import React from 'react';
import MyBookings from './my-bookings/MyBookings';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

const Bookings = () => {
  return (
    <>
      <Helmet>
        <title>Bookings - DeTalks</title>
      </Helmet>
      <div style={{ padding: 'var(--space-sm)' }}>
        <Routes>
          <Route path="/" element={<MyBookings />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Bookings;
