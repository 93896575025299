import { AppBar, Tab, Tabs } from '@mui/material';
import { NavLink, Navigate, Route, Routes } from 'react-router-dom';
import ServiceList from './service-list/ServiceList';
import { Helmet } from 'react-helmet';

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Services - DeTalks</title>
      </Helmet>
      <div style={{ padding: 'var(--space-unit)' }}>
        <Routes>
          <Route path="/" element={<ServiceList />} />
        </Routes>
      </div>
      <style jsx>{`
        :global(.active) {
          color: var(--primary-color);
        }
      `}</style>
    </>
  );
};

export default Services;
