import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchHistory } from './clientHistory.service.js';
import ClientHistoryItem from './ClientHistoryItem.js';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';

const ClientHistoryList = () => {
  const [history, setHistory] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const user = useStoreState((state) => state.user);

  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const {
      data: newData,
      hasMore: newHasMore,
      lastVisible: newLastVisible
    } = await fetchHistory(lastVisible, user.orgId, id);

    if (!newHasMore) {
      setHasMore(false);
    }
    if (newData.length > 0) {
      const updatedHistory = history ? [...history, ...newData] : newData;
      setHistory(updatedHistory);
      setLastVisible(newLastVisible);
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={history ? history.length : 0}
        next={fetchData}
        hasMore={hasMore}
        loader={<p className="optional-heading margin-top">Loading...</p>}
        endMessage={
          history && history.length > 0 ? (
            <p className="optional-heading margin-top">That&apos;s All !</p>
          ) : (
            <p className="optional-heading margin-top">No History !</p>
          )
        }>
        {history && history.map((data) => <ClientHistoryItem key={data.id} historyItem={data} />)}
      </InfiniteScroll>

      <style jsx>
        {`
          .optional-heading {
            text-align: center;
            font-size: 18px;
          }
        `}
      </style>
    </>
  );
};

export default ClientHistoryList;
