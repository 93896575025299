import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';

const AboutMe = ({ control }) => {
  return (
    <>
      <div className="form-row flex-container">
        <div className="field margin-top--md margin-bottom--xs">
          <Controller
            name="aboutMe"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="About me"
                variant="outlined"
                size="small"
                value={value}
                // required
                fullWidth
                onChange={onChange}
                error={!!error}
                multiline
                rows={3}
                inputProps={{
                  maxLength: 2000
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        alignSelf: 'flex-start',
                        marginTop: '12px'
                      }}>
                      <CreateOutlinedIcon color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            )}
            // rules={{ required: "Please fill this field" }}
          />
        </div>
      </div>
      <style jsx>{`
        .form-row {
          //   justify-content: space-between;
          //   flex-wrap: wrap;
        }
        .field {
          width: 49%;
          //   min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default AboutMe;
