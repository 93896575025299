/* eslint-disable no-undef */
import { FileCopyOutlined } from '@mui/icons-material';
import { Fab, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { debounce } from '../../../helper/util.helper';
import { checkForDomain, getDomainFromDb, storeDomain } from './domain.service';

const Domain = () => {
  const [domainData, setDomainData] = useState();
  const [domainAvailable, setDomainAvailable] = useState('');
  const practitionerData = useStoreState((state) => state.practitionerData);
  const { handleSubmit, control, reset, formState } = useForm();

  const checkDomainAvailability = async (domain) => {
    setDomainAvailable(await checkForDomain(domain?.toLowerCase()));
  };

  const debouncedSearch = useCallback(debounce(checkDomainAvailability, 1000), []);

  const setSiteDomain = (e, update) => {
    update(e.target.value);
    if (!domainData?.siteDomain) {
      debouncedSearch(e.target.value);
    }
  };

  const copySiteDomain = (domain) => {
    navigator.clipboard.writeText(`https://${domain}.detalks.com`);
    toast.success('copied');
  };

  const onSubmit = async (data) => {
    data.siteDomain = data.siteDomain.toLowerCase();
    const isDomainAvailable = await checkForDomain(data.siteDomain);
    if (!isDomainAvailable && !domainData?.siteDomain) {
      setDomainAvailable(false);
      return;
    }

    await toast.promise(
      storeDomain(practitionerData.own_organisation, { siteDomain: data.siteDomain.toLowerCase() }),
      {
        loading: 'Saving...',
        success: 'Saved!',
        error: `Couldn't to save`
      }
    );
    getDomainData();
  };

  useEffect(() => {
    getDomainData();
  }, []);

  const getDomainData = () => {
    getDomainFromDb(practitionerData.own_organisation).then((data) => {
      setDomainData(data);
    });
  };

  useEffect(() => {
    reset(domainData);
  }, [domainData, reset]);

  return (
    <div className="container">
      <Helmet>
        <title>Domain - DeTalks</title>
      </Helmet>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>DeTalks site domain</p>
        <hr />
        <p className="text--sm">
          Please choose your DeTalks site domain carefully. This can NOT be changed later.
        </p>
        <div className="field margin-top margin-bottom--xs">
          <Controller
            name="siteDomain"
            control={control}
            defaultValue=""
            rules={{
              required: 'Please fill this field',
              pattern: /^[a-zA-Z0-9]*[a-zA-Z0-9]$/
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className="flex-container">
                <TextField
                  label="Site domain"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={value}
                  disabled={!!domainData?.siteDomain}
                  fullWidth
                  onChange={(e) => setSiteDomain(e, onChange)}
                  error={!!error || domainAvailable === false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p>.detalks.com</p>
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    error
                      ? error.message
                      : value && domainAvailable !== ''
                        ? domainAvailable
                          ? 'Domain Available'
                          : 'Domain not available'
                        : 'Tip: Choose a domain name which is easier for your clients to remember.'
                  }
                />
                {domainData?.siteDomain && (
                  <div className="copy-icon margin-top--xxs">
                    <FileCopyOutlined onClick={() => copySiteDomain(value)} />
                  </div>
                )}
              </div>
            )}
          />
        </div>
        <div className="floating-button">
          <Fab
            style={{ width: '120px' }}
            variant="extended"
            color="primary"
            type="submit"
            disabled={!formState.isValid || !!domainData?.siteDomain}>
            Confirm
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .container {
          padding: 15px 0px;
        }
        .floating-button {
          position: fixed;
          bottom: 30px;
          right: 10%;
        }
        .field {
          max-width: 500px;
          position: relative;
        }
        .copy-icon {
          margin-left: 10px;
          cursor: pointer;
          height: fit-content;
        }
        @media only screen and (max-width: 600px) {
          .floating-button {
            bottom: 60px;
          }
        }
      `}</style>
    </div>
  );
};

export default Domain;
