import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { Helmet } from 'react-helmet';
import style from './forgot.module.scss';
import { auth } from '../../helper/firebase.helper';
import { fetchSignInMethodsForEmail, sendPasswordResetEmail } from 'firebase/auth';

const ForgotPassword = () => {
  const [isMailSent, setIsMailSent] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const sendLink = (e) => {
    e.preventDefault();
    fetchSignInMethodsForEmail(auth, email)
      .then((res) => {
        if (res.length) {
          sendPasswordResetEmail(auth, email).then(() => {
            setIsMailSent(true);
          });
        } else {
          setError('No user exists with provided email');
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleEmail = (e) => {
    setError('');
    setEmail(e.target.value);
  };

  return (
    <div className={style.page_wrapper}>
      <Helmet>
        <title>Forgot password- DeTalks</title>
      </Helmet>
      <div className={style.wrapper}>
        <form autoComplete="off" onSubmit={sendLink}>
          <h2>Recover Password</h2>
          {isMailSent && (
            <p className={style.sent}>
              We have sent a recovery link to {email}. Follow the instructions in your email to
              proceed.
            </p>
          )}
          {!isMailSent && (
            <>
              <div className={style.field}>
                <TextField
                  required
                  label="Email"
                  id="email"
                  variant="outlined"
                  style={{ width: '100%' }}
                  value={email}
                  error={!!error}
                  helperText={error}
                  onChange={handleEmail}
                />
              </div>
              <div className={style.field}>
                <Button
                  onClick={sendLink}
                  type="submit"
                  style={{ width: '100%' }}
                  variant="contained"
                  size="large"
                  color="primary">
                  Send recovery link
                </Button>
              </div>
              <p className={style.login}>
                Back to{' '}
                <Link className={style.sign_in} to={`/`}>
                  Sign in
                </Link>
              </p>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
