import { InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Proof from '../shared/Proof';

const GovernmentId = ({ control, setValue, getValues, verified }) => {
  const [governmentProofUrl, setGovernmentProofUrl] = useState('');

  const watchedGovtProofUrl = useWatch({
    control,
    name: 'governmentId.proofUrl'
  });

  useEffect(() => {
    const storedProofUrl = getValues('governmentId.proofUrl');
    if (storedProofUrl) {
      setGovernmentProofUrl(storedProofUrl);
    }
  }, [getValues, watchedGovtProofUrl, setValue]);

  const handleGovtProofUpload = (file) => {
    setValue('governmentIdProof', { file: file });
  };

  const deleteProofFile = async () => {
    if (!verified) {
      const url = await getValues('governmentId.proofUrl');
      setValue('deleteGovernmentIdProof', url);
    } else {
      setValue('governmentIdProof', null);
    }
    setValue('governmentId.proofUrl', '');
    setGovernmentProofUrl('');
  };

  return (
    <>
      <div className="form-row flex-container direction-column">
        <div className="flex-container  field margin-top--md">
          <Controller
            name="governmentId.value"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Government-issued ID"
                variant="outlined"
                size="small"
                value={value}
                required={verified}
                fullWidth
                onChange={onChange}
                error={!!error}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlinedIcon color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            )}
            rules={verified ? { required: 'Please fill this field' } : {}}
          />
        </div>
        <Proof
          path="governmentId"
          existingUrl={governmentProofUrl}
          onUpload={handleGovtProofUpload}
          deleteProofFile={deleteProofFile}
        />
      </div>
      <style jsx>{`
        .field {
          width: 49%;
          //   min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default GovernmentId;
