import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, database, storage } from '../../../../helper/firebase.helper';
import { getFileExtension } from '../../../../helper/util.helper';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

export const getPersonalData = async () => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  const personalDocRef = doc(database, `practitioners/${user.uid}/details/personal`);
  const personalDocSnap = await getDoc(personalDocRef);

  if (personalDocSnap.exists()) {
    return personalDocSnap.data();
  } else {
    console.log('No such document!');
    return null;
  }
};

export const storePersonalToDb = async (data) => {
  const user = auth.currentUser;
  if (!user) throw new Error('User not authenticated');

  let profilePicData;
  const userId = user.uid;

  const uploadTasks = [];

  if (data.profilePic) {
    const profilePicRef = ref(
      storage,
      `users/${userId}/assets/profilePic.${getFileExtension(data.profilePic.file.name)}`
    );
    const profilePicUploadTask = uploadBytes(profilePicRef, data.profilePic.file).then(async () => {
      const proPicURL = await getDownloadURL(profilePicRef);
      profilePicData = {
        name: 'profilePic',
        cropData: { ...data.profilePic.cropData },
        newImageUrl: proPicURL,
        ...(data.profilePicUrl && { oldImageUrl: data.profilePicUrl })
      };
      data.profilePicUrl = proPicURL;
      delete data.profilePic;
    });
    uploadTasks.push(profilePicUploadTask);
  }

  if (data.deleteGovernmentIdProof) {
    const fileRef = ref(storage, data.deleteGovernmentIdProof);
    deleteObject(fileRef);

    delete data.deleteGovernmentIdProof;
  }
  if (data.governmentIdProof) {
    const govIdFolderRef = ref(storage, `users/${userId}/proofs/governmentId`);
    const deletePreviousGovtFiles = listAll(govIdFolderRef)
      .then((res) => {
        const deletePromises = res.items.map((itemRef) => {
          return deleteObject(itemRef);
        });
        return Promise.all(deletePromises);
      })
      .catch((error) => {
        console.error('Error deleting previous files: ', error);
      });
    const govIdProofRef = ref(
      storage,
      `users/${userId}/proofs/governmentId/${data.governmentIdProof.file.name}`
    );
    const govIdUploadTask = uploadBytes(govIdProofRef, data.governmentIdProof.file).then(
      async () => {
        const govIdURL = await getDownloadURL(govIdProofRef);
        data.governmentId.proofUrl = govIdURL;
        delete data.governmentIdProof;
      }
    );
    uploadTasks.push(govIdUploadTask);
  }

  if (data.deleteExperienceProof) {
    const fileRef = ref(storage, data.deleteExperienceProof);
    deleteObject(fileRef);
    delete data.deleteExperienceProof;
  }

  if (data.experienceProof) {
    const expFolderRef = ref(storage, `users/${userId}/proofs/experience`);
    const deletePreviousExpFiles = listAll(expFolderRef)
      .then((res) => {
        const deletePromises = res.items.map((itemRef) => {
          return deleteObject(itemRef);
        });
        return Promise.all(deletePromises);
      })
      .catch((error) => {
        console.error('Error deleting previous files: ', error);
      });
    const expProofRef = ref(
      storage,
      `users/${userId}/proofs/experience/${data.experienceProof.file.name}`
    );
    const expUploadTask = uploadBytes(expProofRef, data.experienceProof.file).then(async () => {
      const expURL = await getDownloadURL(expProofRef);
      data.experience.proofUrl = expURL;
      delete data.experienceProof;
    });
    uploadTasks.push(expUploadTask);
  }

  const deleteTasks = [];

  if (data.deleteLicenseProof) {
    const deleteLicenseTasks = data.deleteLicenseProof.map(async (fileUrl) => {
      const fileRef = ref(storage, fileUrl);
      return deleteObject(fileRef);
    });
    // await Promise.all(deleteLicenseTasks);
    deleteTasks.push(...deleteLicenseTasks);
    delete data.deleteLicenseProof;
  }

  if (data.licenseProof) {
    const licenseUploadTasks = data.licenseProof.map(async (proof, i) => {
      if (proof) {
        const licenseProofRef = ref(storage, `users/${userId}/proofs/license/${proof.fileName}`);
        return uploadBytes(licenseProofRef, proof.file).then(async () => {
          const licenseURL = await getDownloadURL(licenseProofRef);
          data.license[proof.index].proofUrl = licenseURL;
        });
      }
    });
    uploadTasks.push(...licenseUploadTasks);
    delete data.licenseProof;
  }

  if (data.deleteEducationProof) {
    const deleteEducationTasks = data.deleteEducationProof.map(async (fileUrl) => {
      const fileRef = ref(storage, fileUrl);
      return deleteObject(fileRef);
    });
    // await Promise.all(deleteEducationTasks);
    deleteTasks.push(...deleteEducationTasks);
    delete data.deleteEducationProof;
  }

  if (data.educationProof) {
    const educationUploadTasks = data.educationProof.map(async (proof, i) => {
      if (proof) {
        const educationProofRef = ref(
          storage,
          `users/${userId}/proofs/education/${proof.fileName}`
        );
        return uploadBytes(educationProofRef, proof.file).then(async () => {
          const educationURL = await getDownloadURL(educationProofRef);
          data.education[proof.index].proofUrl = educationURL;
        });
      }
    });
    uploadTasks.push(...educationUploadTasks);
    delete data.educationProof;
  }

  await Promise.all(deleteTasks);
  await Promise.all(uploadTasks);

  if (profilePicData) {
    const profilePicDocRef = doc(collection(database, `practitioners/${userId}/crop_image`));
    await setDoc(profilePicDocRef, profilePicData);
  }

  const personalDocRef = doc(database, `practitioners/${userId}/details/personal`);

  return await setDoc(personalDocRef, data);
};

export const languagesList = [
  { label: 'English' },
  { label: 'Hindi' },
  { label: 'Marathi' },
  { label: 'Punjabi' },
  { label: 'Spanish' },
  { label: 'French' },
  { label: 'German' },
  { label: 'Chinese' }
];

export const experienceOptions = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20
];

export const roleOptions = [
  'Clinical Psychologist',
  'Therapist',
  'Counselor',
  'Psychiatrist',
  'Social Worker',
  'Behavioral Therapist',
  'Family Therapist',
  'Marriage Counselor',
  'School Counselor',
  'Rehabilitation Counselor',
  'Mental Health Specialist',
  'Psychoanalyst',
  'Psychotherapist'
];

export const specialitiesList = [
  { label: 'Depression' },
  { label: 'Anxiety' },
  { label: 'ADHD' },
  { label: 'OCD' },
  { label: 'Anger Management' },
  { label: 'Stress Management' },
  { label: 'PTSD' },
  { label: 'Sleep Disorders' },
  { label: 'Relationship Issues' },
  { label: 'Family Therapy' },
  { label: 'Grief Counseling' },
  { label: 'Panic Disorders' },
  { label: 'Social Anxiety' },
  { label: 'Mood Disorders' },
  { label: 'Child and Adolescent Therapy' },
  { label: 'Couples Counseling' },
  { label: 'Addiction' },
  { label: 'Chronic Pain' },
  { label: 'Career Counseling' },
  { label: 'Life Transitions' },
  { label: 'Parenting' },
  { label: 'Autism Spectrum Disorders' },
  { label: 'Cognitive Behavioral Therapy (CBT)' }
];

export const modalitiesList = [
  { label: 'Depression' },
  { label: 'Anxiety' },
  { label: 'ADHD' },
  { label: 'OCD' },
  { label: 'Anger Management' },
  { label: 'Stress Management' },
  { label: 'PTSD' },
  { label: 'Sleep Disorders' },
  { label: 'Relationship Issues' },
  { label: 'Family Therapy' },
  { label: 'Grief Counseling' },
  { label: 'Panic Disorders' },
  { label: 'Social Anxiety' },
  { label: 'Mood Disorders' },
  { label: 'Child and Adolescent Therapy' },
  { label: 'Couples Counseling' },
  { label: 'Addiction' },
  { label: 'Chronic Pain' },
  { label: 'Career Counseling' },
  { label: 'Life Transitions' },
  { label: 'Parenting' },
  { label: 'Autism Spectrum Disorders' },
  { label: 'Cognitive Behavioral Therapy (CBT)' }
];

export const worksWithList = [
  { label: 'Children ' },
  { label: 'Adolescents ' },
  { label: 'Young Adults ' },
  { label: 'Adults ' },
  { label: 'Seniors ' }
];
