import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { invitePractitioner, resendGroupPracticeInvite } from '../../../../helper/api.helper';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden
} from '@mui/material';
import { formatRelative } from 'date-fns';

const InviteHistoryItem = ({
  inviteItem,
  practitioner
  //   isInGroupPractice,
  //   seatsAvailable,
  //   usedSeats,
}) => {
  let isAdmin;
  const [open, setOpen] = useState(false);
  const openConfirmationDialog = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const statusLabel = {
    1: 'Invited',
    2: 'Accepted',
    3: 'Rejected',
    4: 'Expired',
    5: 'Withdrawn',
    6: 'Removed',
    7: 'Exited'
  };

  const inviteStatus = {
    INVITED: 1,
    ACCEPTED: 2,
    REJECTED: 3,
    EXPIRED: 4,
    WITHDRAWN: 5,
    REMOVED: 6,
    EXITED: 7
  };

  const statusColor = {
    1: '#ffcc00',
    2: '#34c759',
    3: '#ff3b30',
    4: '#ff3b30',
    5: '#ff3b30',
    6: '#ff3b30',
    7: '#ff3b30'
  };

  const changeInviteStatus = async (status) => {
    //  if (status === inviteStatus.ACCEPTED) {
    //    toast.loading("...Updating status");
    //    await updateDoc(
    //      doc(firestore, "group_practice_invites", inviteItem.id),
    //      {
    //        status,
    //      }
    //    );
    //    const unsubscribe = onSnapshot(
    //      doc(firestore, "completed_tasks", practitioner.uid),
    //      (snap) => {
    //        if (snap.get("task") === "accept_invite") {
    //          if (snap.get("expire_timestamp") > Date.now()) {
    //            toast.dismiss();
    //            unsubscribe();
    //            if (snap.get("status") === "success") {
    //              dispatch(getPractitionerInfo(practitioner.uid));
    //              dispatch(getPlanInfo());
    //              toast.success("Invite accepted");
    //            } else {
    //              toast.error("Failed to update status");
    //            }
    //          }
    //        }
    //      }
    //    );
    //  } else if (
    //    inviteItem.status === inviteStatus.ACCEPTED &&
    //    status === inviteStatus.EXITED
    //  ) {
    //    toast.loading("Updating status...");
    //    await updateDoc(
    //      doc(firestore, "group_practice_invites", inviteItem.id),
    //      {
    //        status,
    //      }
    //    );
    //    const unsubscribe = onSnapshot(
    //      doc(firestore, "completed_tasks", practitioner.uid),
    //      (snap) => {
    //        if (snap.get("task") === "left_group") {
    //          if (snap.get("expire_timestamp") > Date.now()) {
    //            toast.dismiss();
    //            unsubscribe();
    //            if (snap.get("status") === "success") {
    //              dispatch(getPractitionerInfo(practitioner.uid));
    //              dispatch(getPlanInfo());
    //              toast.success("Exited!");
    //            } else {
    //              toast.error("Failed to update status");
    //            }
    //          }
    //        }
    //      }
    //    );
    //  } else {
    //    toast.promise(
    //      updateDoc(doc(firestore, "group_practice_invites", inviteItem.id), {
    //        status,
    //      }).then(() => dispatch(getPractitionerInfo(practitioner.uid))),
    //      {
    //        loading: "Updating status...",
    //        success: "Status updated successfully!",
    //        error: `Failed to update status`,
    //      }
    //    );
    //  }
  };

  const sendInviteAgain = () => {
    toast.promise(resendGroupPracticeInvite({ inviteId: inviteItem.id }), {
      loading: 'Resending invitation...',
      success: 'Resent successfully!',
      error: `Failed to send!`
    });
  };

  const invitePract = () => {
    toast.loading('Inviting practitioner...');
    invitePractitioner({ email: inviteItem.member_email })
      .then((res) => res.json())
      .then((res) => {
        toast.dismiss();
        if (res.status === 'success') {
          toast.success('Practitioner invited');
          //    dispatch(getPractitionerInfo(practitioner.uid));
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(`Failed to invite - ${err.message}`);
      });
  };

  return (
    <div className="row margin-top--md margin-bottom--md flex-container">
      <div className="item text--sm flex-container align-center">
        <Avatar
          style={{
            width: '30px',
            height: '30px',
            fontSize: '16px',
            marginRight: '5px',
            backgroundColor: statusColor[inviteItem.status]
          }}>
          {inviteItem[isAdmin ? 'member_email' : 'admin_email']?.toUpperCase()[0]}
        </Avatar>
        {inviteItem[isAdmin ? 'member_email' : 'admin_email']}
      </div>
      <Hidden smDown>
        <p className="item text--sm">{inviteItem[isAdmin ? 'member_name' : 'admin_name']}</p>
      </Hidden>
      <Hidden smDown>
        <p className="item text--sm">
          <span style={{ color: statusColor[inviteItem.status] }}>
            {statusLabel[inviteItem.status]}
          </span>{' '}
          {inviteItem.id === 'admin'
            ? 'Administrator'
            : formatRelative(inviteItem.updated_time || new Date(), new Date())}
        </p>
      </Hidden>
      <div className="item text--sm">
        {isAdmin ? (
          <>
            {inviteItem.status === inviteStatus.INVITED ? (
              <Button onClick={sendInviteAgain} fullWidth color="primary" variant="contained">
                Resend invitation
              </Button>
            ) : null}
            {inviteItem.status === inviteStatus.ACCEPTED ? (
              <Button
                onClick={() => changeInviteStatus(inviteStatus.REMOVED)}
                fullWidth
                color="primary"
                variant="contained">
                Remove practitioner
              </Button>
            ) : null}
            {inviteItem.status === inviteStatus.EXITED ||
            inviteItem.status === inviteStatus.EXPIRED ||
            inviteItem.status === inviteStatus.REMOVED ||
            inviteItem.status === inviteStatus.WITHDRAWN ||
            inviteItem.status === inviteStatus.REJECTED ? (
              <Button
                onClick={invitePract}
                fullWidth
                color="primary"
                variant="contained"
                // disabled={!seatsAvailable}
              >
                Invite again
              </Button>
            ) : null}
            {inviteItem.status === inviteStatus.INVITED ||
            inviteItem.status === inviteStatus.EXITED ||
            inviteItem.status === inviteStatus.EXPIRED ||
            inviteItem.status === inviteStatus.REMOVED ||
            inviteItem.status === inviteStatus.WITHDRAWN ||
            inviteItem.status === inviteStatus.REJECTED ? (
              <div className="margin-top--sm">
                <Button
                  disabled={
                    inviteItem.status === inviteStatus.EXITED ||
                    inviteItem.status === inviteStatus.EXPIRED ||
                    inviteItem.status === inviteStatus.REMOVED ||
                    inviteItem.status === inviteStatus.WITHDRAWN ||
                    inviteItem.status === inviteStatus.REJECTED
                  }
                  onClick={() => changeInviteStatus(inviteStatus.WITHDRAWN)}
                  type="text"
                  fullWidth
                  color="primary">
                  withdraw invitation
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {inviteItem.status === inviteStatus.INVITED ? (
              <>
                <Button
                  //   onClick={() =>
                  //     isInGroupPractice || usedSeats < 2
                  //       ? changeInviteStatus(inviteStatus.ACCEPTED)
                  //       : openConfirmationDialog()
                  //   }
                  fullWidth
                  color="primary"
                  variant="contained">
                  Accept invitation
                </Button>
                <div className="margin-top--sm">
                  <Button
                    onClick={() => changeInviteStatus(inviteStatus.REJECTED)}
                    type="text"
                    fullWidth
                    color="primary">
                    Reject invitation
                  </Button>
                </div>
              </>
            ) : null}
            {inviteItem.status === inviteStatus.ACCEPTED ? (
              <Button
                onClick={() => changeInviteStatus(inviteStatus.EXITED)}
                type="text"
                fullWidth
                color="primary">
                Exit
              </Button>
            ) : null}
          </>
        )}
      </div>
      <div />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Accepting this invitation will remove all the current members of your group practice.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              handleClose();
              changeInviteStatus(inviteStatus.ACCEPTED);
            }}
            color="primary"
            autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <style jsx>{`
        .item {
          flex: 1;
          text-overflow: ellipsis;
          height: fit-content;
        }
        .row {
          justify-content: start;
        }
      `}</style>
    </div>
  );
};

export default InviteHistoryItem;
