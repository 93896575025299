import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Proof from '../shared/Proof';
import toast from 'react-hot-toast';

const Education = ({ control, setValue, getValues, verified }) => {
  const {
    fields: educations,
    append: addEducation,
    remove: removeEducation
  } = useFieldArray({
    control,
    name: 'education'
  });

  useEffect(() => {
    if (educations.length === 0) {
      addEducation({ education: '' });
    }
  }, [educations, addEducation]);

  const [educationProofUrls, setEducationProofUrls] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEducationIndex, setSelectedEducationIndex] = useState(null);

  const watchedEducationProofUrls = useWatch({
    control,
    name: educations.map((_, index) => `education.${index}.proofUrl`)
  });

  useEffect(() => {
    const storedProofUrls = educations.map((_, index) => getValues(`education.${index}.proofUrl`));
    setEducationProofUrls(storedProofUrls);
  }, [educations, getValues, watchedEducationProofUrls]);

  const handleProofUpload = (file, index) => {
    const timestamp = Date.now();
    setValue(`educationProof.${index}`, {
      index: index,
      file: file,
      fileName: `${file.name}.${timestamp}`
    });
    // const updatedProofUrls = [...educationProofUrls];
    // updatedProofUrls[index] = url;
    // setEducationProofUrls(updatedProofUrls);
  };

  const deleteEducation = async (index) => {
    const educationProofUrl = educationProofUrls[index] || getValues(`education.${index}.proofUrl`);
    if (educationProofUrl) {
      const currentDeleteEducationProof = (await getValues('deleteEducationProof')) || [];
      currentDeleteEducationProof.push(educationProofUrl);
      await setValue('deleteEducationProof', currentDeleteEducationProof);
      const isEducationProof = await getValues(`educationProof.${index}`);
      if (isEducationProof) {
        setValue(`educationProof.${index}`, null);
      }
      setValue(`education.${index}.proofUrl`, '');
      if (educationProofUrls?.[index]) {
        educationProofUrls[index] = '';
      }
    }
    removeEducation(index);
    toast.success(`Education ${index + 1} deleted successfully.`);
  };

  const deleteEducationProofFile = async (index) => {
    const fileToBeDeletedFromStorage = await getValues(`education.${index}.proofUrl`);
    if (fileToBeDeletedFromStorage) {
      // await setValue(`deleteEducationProof.${index}`, fileToBeDeletedFromStorage);
      const currentDeleteEducationProof = (await getValues('deleteEducationProof')) || [];
      currentDeleteEducationProof.push(fileToBeDeletedFromStorage);
      await setValue('deleteEducationProof', currentDeleteEducationProof);
    }
    const isEducationProof = await getValues(`educationProof.${index}`);
    if (isEducationProof) {
      setValue(`educationProof.${index}`, null);
    }
    await setValue(`education.${index}.proofUrl`, '');
    if (educationProofUrls?.[index]) {
      educationProofUrls[index] = '';
    }
  };

  const handleDeleteClick = (index) => {
    setSelectedEducationIndex(index);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (selectedEducationIndex !== null) {
      deleteEducation(selectedEducationIndex);
      setSelectedEducationIndex(null);
      setOpenDialog(false);
    }
  };

  const cancelDelete = () => {
    setSelectedEducationIndex(null);
    setOpenDialog(false);
  };

  return (
    <>
      <div className="heading-div ">
        <b>Education</b>
      </div>
      {educations.map((item, index) => (
        <React.Fragment key={item.id}>
          <div key={item.id} className="form-row flex-container align-center">
            <div className="field flex-container  margin-top--md">
              <Controller
                name={`education.${index}.education`}
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label={`Education/Qualification ${index + 1}`}
                    variant="outlined"
                    size="small"
                    value={value}
                    fullWidth
                    placeholder="M.Phil. Psychology/ M.SC. Psychology/ Ph.D. Psychology ..."
                    onChange={onChange}
                    required={verified}
                    error={!!error}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SchoolOutlinedIcon color="primary" />
                        </InputAdornment>
                      )
                    }}
                    helperText={error ? error.message : ''}
                  />
                )}
                rules={verified ? { required: 'Please fill this field' } : {}}
              />
            </div>
            <IconButton
              sx={{
                marginTop: '18px',
                width: '40px',
                height: '40px'
              }}
              onClick={() => handleDeleteClick(index)}
              color="secondary"
              disabled={educations.length === 0}
              size="small">
              <DeleteOutlineIcon />
            </IconButton>
          </div>
          <div>
            <Proof
              path={`education[${index}]`}
              existingUrl={educationProofUrls[index] || ''}
              onUpload={(file) => handleProofUpload(file, index)}
              deleteProofFile={() => deleteEducationProofFile(index)}
            />
          </div>
        </React.Fragment>
      ))}
      <Button
        sx={{ marginTop: '10px' }}
        variant="text"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => addEducation({ education: '' })}>
        Add More
      </Button>
      <Dialog open={openDialog} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this education?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <style jsx>{`
        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 15px 5px;
          margin-top: 10px;
          font-size: 18px;
        }
        .field {
          width: 49%;
          // min-width: 300px;
          position: relative;
        }

        @media only screen and (max-width: 600px) {
          .field {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
};

export default Education;
