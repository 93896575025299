export const getDate = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    day: 'numeric'
  };

  const formattedDate = date.toLocaleString(undefined, optionsDate);

  return formattedDate;
};

export const getMonthYear = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    month: 'short',
    year: 'numeric'
  };

  const formattedMonthYear = date.toLocaleString(undefined, optionsDate);

  return formattedMonthYear;
};
export const getDay = (timestamp) => {
  const date = new Date(timestamp);

  const optionsDate = {
    weekday: 'short'
  };

  const formattedDay = date.toLocaleString(undefined, optionsDate);

  return formattedDay;
};
